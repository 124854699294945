import { React, useState } from 'react';
import { Link } from "react-router-dom";
function Header() {
    
    return (
        <header className= "header" >
            <h1><img className="logo" src={process.env.PUBLIC_URL + "/logo4.png"} alt="Company logo"/></h1>
            <nav className="header__nav">
                <ul className="header__nav-list row">

                    <li className="header__nav-item col-sm col-md adjProfile"><Link to={"home"} >HOME </Link> </li>
                    <li className="header__nav-item col-sm col-md"><Link to={"about-us"} >ABOUT US</Link> </li>
                    <li className="header__nav-item col-sm col-md"><Link to={"courses"} >COURSES </Link> </li>
                    <li className="header__nav-item col-sm col-md"><Link to={"contact-us"} >CONTACT US </Link> </li>

                </ul>
            </nav>
        </header>
    );
}

export default Header;
