import { Carousel } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";

function Home() {

    return (
        <div className='container-fluid' id="home"
        style={{

        }}
        
        >
            <h1 className="company-title">
                <p className="golden"> Golden </p>
                <p className="craft"> Crafts Academy </p>
            </h1>

        <div className='home-carousel'>
            <Carousel interval = "3000">
                <Carousel.Item>
                    <p>
                        Learn how to fix <br/> mobile phones
                    </p>
                </Carousel.Item>

                <Carousel.Item>
                    <p>
                        More practical <br/> less theory
                    </p>
                </Carousel.Item>

                <Carousel.Item>
                    <p>
                        We are the best <br/> at what we do
                    </p>
                </Carousel.Item>

                <Carousel.Item>
                    <p>
                        What are you <br/> waiting for? 
                    </p>
                </Carousel.Item>
                
            </Carousel>
            </div>
        </div>
    );
}

export default Home;