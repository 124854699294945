import { FileSaver, saveAs } from "file-saver";
import { useState, useEffect } from "react";
import { Carousel } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";


const AboutUs = () => {
    
    const message = `Just as our motto says "knowledge is power" Golden Craft Academy is ready to instill in-dept knowledge in micro-soldering. Our course will take a deep dive into micro-soldering techniques to ensure our students masters board repairs for Mobile electronics. Therefore our academy is offfering a repair course which is divided into 3, beginners, intermediate and masters. Feel free to navigate to our course section above to learn more.`
    const [displayedMessage, setDisplayedMessage] = useState("");

    const [Q1_isExpanded, setQ1_IsExpanded] = useState(false);
    const [Q2_isExpanded, setQ2_IsExpanded] = useState(false);
    const [Q3_isExpanded, setQ3_IsExpanded] = useState(false);
    const [Q4_isExpanded, setQ4_IsExpanded] = useState(false);
    const [Q5_isExpanded, setQ5_IsExpanded] = useState(false);
    const [Q6_isExpanded, setQ6_IsExpanded] = useState(false);
    const [Q7_isExpanded, setQ7_IsExpanded] = useState(false);
    const [Q8_isExpanded, setQ8_IsExpanded] = useState(false);
    const [Q9_isExpanded, setQ9_IsExpanded] = useState(false);
 
    const [aboutUsClass, setAboutUsClass] =useState("aboutUs-div")


    const downloadForm = () => {
      const cvFile = process.env.PUBLIC_URL + "Form.pdf";
      fetch(cvFile)
          .then(res => res.blob())
          .then(blob => {
              const file = new File([blob], "Form.pdf", { type: "application/pdf" });
              saveAs(file);
          })
  }

      setTimeout(()=>{
        setAboutUsClass("aboutUs-div-2")
      }, 0)

    useEffect(() => {
        let i = 0;
        const CodeInterval = setInterval(() => {
            setDisplayedMessage((prevMessage) => prevMessage + message.charAt(i));
            i++;
            if (i === message.length) {
                clearInterval(CodeInterval);
            }
        }, 60);

        return () => clearInterval(CodeInterval);


    }, [message]);



    return (
        <div className="AboutUs-body">

<div className="aboutUs-carousel">
            <Carousel fade interval = "3000">
                <Carousel.Item>
                    <img className="d-block carousel-Image" src={process.env.PUBLIC_URL + "/first.jpg"} alt="Class room front view"/>
                </Carousel.Item>

                <Carousel.Item>
                    <img className="d-block carousel-Image" src={process.env.PUBLIC_URL + "/second.jpg"} alt="Class room side view"/>
                </Carousel.Item>

                <Carousel.Item>
                    <img className="d-block carousel-Image" src={process.env.PUBLIC_URL + "/third.jpg"} alt="Panel one"/>
                </Carousel.Item>

                <Carousel.Item>
                    <img className="d-block carousel-Image" src={process.env.PUBLIC_URL + "/fourth.jpg"} alt="Panel two"/>
                </Carousel.Item>

                <Carousel.Item>
                    <img className="d-block carousel-Image" src={process.env.PUBLIC_URL + "/fifth.jpg"} alt="Panel three"/>
                </Carousel.Item>
                
            </Carousel>
            </div>


            <div className= {`${aboutUsClass}`}>

                <div id="aboutUs">
                  <h2 className='aboutUs-title'> Golden Craft Academy</h2>

                    <div id="d1">
                        {displayedMessage}
                    </div>
                </div>
            </div>

            <div>
                <h1 className='faq-title'>
                    Frequently Asked Questions
                </h1>

                <div className="faq">


      <div className="faq-question first-child" onClick={()=>{setQ1_IsExpanded(!Q1_isExpanded);}}>
           WHAT COURSES DO YOU OFFER?
        <span className={`faq-icon ${Q1_isExpanded ? 'expanded' : ''}`}>&#x25BC;</span>
      </div>
      {Q1_isExpanded && (
        <div className="faq-answer">
          Our training is comprised of two classes, 
          Beginners and Intermediate which is programmed on a 2 months and 1 month duration respectively,
          Classes are Monday through Friday from 10:00am - 3:00pm.
        </div>
      )}

      <div className="faq-question" onClick={()=>{setQ2_IsExpanded(!Q2_isExpanded);}}>
          WHERE DO YOU OFFER THE TRAINING?
        <span className={`faq-icon ${Q2_isExpanded ? 'expanded' : ''}`}>&#x25BC;</span>
      </div>
      {Q2_isExpanded && (
        <div className="faq-answer">
          90A, Old Ota Road Captain Bus stop,Captain area Oke-odo LGA, Alimosho, Lagos, Nigeria.
        </div>
      )}

      <div className="faq-question" onClick={()=>{setQ3_IsExpanded(!Q3_isExpanded);}}>
          HOW MUCH DOES THE TRAINING COST?
        <span className={`faq-icon ${Q3_isExpanded ? 'expanded' : ''}`}>&#x25BC;</span>
      </div>
      {Q3_isExpanded && (
        <div className="faq-answer">
        The total cost of the course is N500,000 which includes the registration fee and course fee.
        The registration fee is N10,000 and it is non-refundable. The registration fee reserves your 
        space in class and is due during the registration process.<br/>
        The course fee is N250,000 for BEGINNERS &#40; 2 Months duration &#41;.<br/>
        The course fee is N250,000 for INTERMEDIATE &#40; 1 Month duration &#41;.<br/>
        If both courses are selected together, you are required to pay N400,000 for both.<br/> 
        You can pay the course fee during the registration process or in person on the first day of class. 
        All balances must be paid in full in order to participate in class.
        </div>
      )}

      <div className="faq-question" onClick={()=>{setQ4_IsExpanded(!Q4_isExpanded);}}>
        HOW CAN I REGISTER?
        <span className={`faq-icon ${Q4_isExpanded ? 'expanded' : ''}`}>&#x25BC;</span>
      </div>
      {Q4_isExpanded && (
        <div className="faq-answer">
          You can register by downloading and filling the registration form <span className="downloadForm" onClick={() => { downloadForm() }}>HERE</span> or you can set up a scheduled phone call 
          with an account manager to register over the phone. <br/>
          Fill, attach and send a copy of the completed form to <a className="email" href="mailto:Goldencraftsacademyltd@gmail.com" >Goldencraftsacademyltd@gmail.com</a> <br/>
          Call: <a className="tel" href="tel:09132006777">09132006777</a> <br/>
          WhatsApp: <a className="whatsApp" href="https://wa.me/0912008777">0912008777</a>
        </div>
      )}

      <div className="faq-question" onClick={()=>{setQ5_IsExpanded(!Q5_isExpanded);}}>
         WHAT ARE THE REGISTRATION REQUIREMENTS?
        <span className={`faq-icon ${Q5_isExpanded ? 'expanded' : ''}`}>&#x25BC;</span>
      </div>
      {Q5_isExpanded && (
        <div className="faq-answer">
          In order to successfully register to the training, you must have the following information available:<br/>
          * First and Last Name<br/>
          * Residential Address<br/>
          * Contact Phone Number<br/>
          * E-Mail Address<br/>
          * Emergency Contact &#40;Name, Phone Number, Relationship &#41;<br/>
          * Payment Information &#40;Debit/Credit Card&#41; RECEIPT 
        </div>
      )}

      <div className="faq-question" onClick={()=>{setQ6_IsExpanded(!Q6_isExpanded);}}>
         WHAT IS THE MINIMUM AGE REQUIRED TO TAKE THE COURSE?
        <span className={`faq-icon ${Q6_isExpanded ? 'expanded' : ''}`}>&#x25BC;</span>
      </div>
      {Q6_isExpanded && (
        <div className="faq-answer">
          The minimum age is 18.
        </div>
      )}

      <div className="faq-question" onClick={()=>{setQ7_IsExpanded(!Q7_isExpanded);}}>
          IS 3 MONTHS ENOUGH TIME TO LEARN EVERYTHING?
        <span className={`faq-icon ${Q7_isExpanded ? 'expanded' : ''}`}>&#x25BC;</span>
      </div>
      {Q7_isExpanded && (
        <div className="faq-answer">
          Yes! We have a high success rate with our students mainly because our course is 100% hands-on training.
          Our students complete each individual repair from beginning to end.
          They are also trained on how to use the equipment and peripherals required to successfully execute the repairs.
          Upon completion of the course, our students are fully trained to repair cell phones & tablets and will be able to perform these operations independently.
        </div>
      )}

      <div className="faq-question" onClick={()=>{setQ8_IsExpanded(!Q8_isExpanded);}}>
         WHAT BENEFITS DO I HAVE ONCE I COMPLETE THE COURSE?
        <span className={`faq-icon ${Q8_isExpanded ? 'expanded' : ''}`}>&#x25BC;</span>
      </div>
      {Q8_isExpanded && (
        <div className="faq-answer">
          Each student will receive a certificate of completion and will receive post-course technical support.
        </div>
      )}

      <div className="faq-question" onClick={()=>{setQ9_IsExpanded(!Q9_isExpanded);}}>
          HOW CAN I CONTACT YOU?
        <span className={`faq-icon ${Q9_isExpanded ? 'expanded' : ''}`}>&#x25BC;</span>
      </div>
      {Q9_isExpanded && (
        <div className="faq-answer">
          You can call, chat or email us on, <br/>
          Email: <a className="email" href="mailto:Goldencraftsacademyltd@gmail.com" >Goldencraftsacademyltd@gmail.com</a> <br/>
          Tel: <a className="tel" href="tel:09132006777">09132006777</a><br/>
          WhatsApp:  <a className="whatsApp" href="https://wa.me/0912008777">0912008777</a><br/>
          Visit our Social Media Handles on:<br/>
          Twitter, Facebook, & Instagram
        </div>
      )}

    </div>

            </div>



        </div>
    );
}

export default AboutUs;