import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from "./Components/Contact-us";
import Header from "./Components/Header";
import Home from "./Components/Home";
import AboutUs from "./Components/About-us";
import Courses from "./Components/Courses";
import Error from "./Components/Error";


function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />

        <Routes>

          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/courses" element={<Courses/>} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/*" element={<Error />} />

        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;