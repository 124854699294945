import { useState } from "react"

const Contact = () => {
    const [mapLoadingClass, setMapLoadingClass] =useState("mapLoading")
    const [googleMapClass, setGoogleMapClass] =useState("googleMap")
    const [contactMessageClass, setContactMessageClass] =useState("contactMessage")

      setTimeout(()=>{
        setContactMessageClass("contactMessage2")
      }, 0)


    const showMap = ()=>{
      setMapLoadingClass("mapLoadingNone")
      setGoogleMapClass("googleMap-2")

    }

    return (
        <div className="contactParent">
            <div id="contact">
                <h2 className="contactMessageDiv">
                  
                    <div className= {`${contactMessageClass}`}>
                    <p>Contact us through any of the following</p>

                        <p>Telephone: <br/> <a className="tel" href="tel:09132006777">+234 9132006 777</a></p>
                        <p>WhatsApp: <br/> <a className="whatsApp" href="https://wa.me/0912008777">+234 912008 777</a></p>
                        <p>Email: <br/> <a className="email" href="mailto:Goldencraftsacademyltd@gmail.com" >Goldencraftsacademyltd@gmail.com</a></p>
                        </div>
                        {/* <p className="contactmeChild">Visit our Social Media Handles <a className="twitter" href="https://www" target="_blank" >Twitter</a> <a className="facebook" href="https://www" target="_blank" > Facebook </a> <a className="instagram" href="https://www" target="_blank" >Instagram </a></p> */}


                </h2>
            <div className="googleMapParent" >
            <div className= {`${mapLoadingClass}`} > loading Address from Google Map...</div>
            <iframe onLoad ={()=>{showMap()}} className= {`${googleMapClass}`} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3962.9683259923786!2d3.279245374183401!3d6.650848021672053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b9155d8493269%3A0x44d5f6e8e9b1ee9b!2sMec%20health%20Family%20hospital!5e0!3m2!1sen!2sng!4v1690205529642!5m2!1sen!2sng" alt="Golden Craft Academy on Google Map" style={{ loading:"lazy", referrerpolicy:"no-referrer-when-downgrade"}}>
            </iframe>


            </div>

            </div>

        </div>
    );
}

export default Contact;