import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Error = () => {
    // const nav = useNavigate()
    return (
        <div className="ErrorParent">
            <div className="ErrorMessage">
                Page not found
            </div>
            <Link to={-1}
                className="ErrorLink"
                style={{
                    marginLeft: "7em",
                    fontSize: "5em",
                    border: "solid",
                    borderRadius: "1em",
                    padding: "0.3em",
                    textDecoration: "none",
                    backgroundColor: "white"

                }}
            >BACK</Link>
        </div>
    )
}

export default Error;