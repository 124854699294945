import { FileSaver, saveAs } from "file-saver";

const Courses = ()=>{

    const downloadForm = (event) => {
        const cvFile = process.env.PUBLIC_URL + "Form.pdf";
        fetch(cvFile)
            .then(res => res.blob())
            .then(blob => {
                const file = new File([blob], "Form.pdf", { type: "application/pdf" });
                saveAs(file);
            })
    }

    return (
        <div className="row" id="courses">
            <h2 className="coursesTitle">Welcome to our Academy's Courses Page!</h2>
            <p className="notice">
            Explore Mobile Phone Repair: Beginners & Intermediate sections.
            Beginners - foundational concepts, issue diagnosis. Intermediate - advanced repairs, troubleshooting.<br/>
            Special Discount: N100,000 off when enrolling in both sections.<br/>
            Enroll now by <span className="downloadForm" onClick={() => { downloadForm() }}>ClICKING HERE</span> to download our course registration form<br/>
            Attach and send the completed form to <a href="mailto:Goldencraftsacademyltd@gmail.com" >Goldencraftsacademyltd@gmail.com</a> Thank you! 
            </p>
            <div className="beginners col-md col-sm ">

                <div className="courseHeadings">

                    <h5 className="courseTitle">Beginners</h5>
                    <h5>Cost: ₦250,000</h5>
                    <h5>Duration: 2 months</h5>
                    <h5>Phone-type: Iphone 6 - XR </h5>
                    <h5 className="duration">Time: Monday - Friday &#40;10:00 AM - 3:00 PM&#41; daily</h5>

                </div>
                <ul className="ulParent">
                    <li>The following materials will be acquired during the beginners class:</li>
                    <ul>
                        <li>Itunes error</li>
                        <li>Data Recovery</li>
                        <li>Memory upgrade</li>
                        <li>Fpc Replacement</li>
                        <li>Running jumper with fly line wire</li>
                        <li>Flashing and Unlocking of Iphones</li>
                        <li>Understanding ZXW &#40;Zillion X works&#41; and Wuxinji</li>
                        <li>Identification and understanding function of all ICs on the mother board</li>
                        <li>Trouble shooting, fault finding and repair</li>
                    </ul>
                </ul>
            </div>

            <div className=" intermediate col-md col-sm">

                <div className="courseHeadings">
                <h5 className="courseTitle">Intermediate</h5>
                    <h5>Cost: ₦250,000</h5>
                    <h5>Duration: 1 months</h5>
                    <h5>Phone-type: Iphone XR - 14 Pro-max </h5>
                    <h5 className="duration">Time: Monday - Friday (10:00 AM - 3:00 PM) daily</h5>
                </div>
                <ul className="ulParent">
                <li>The following skills will be acquired during the intermediate class:</li>
                    <ul>
                        <li>Board Swap</li>
                        <li>Panic Error</li>
                        <li>Data Recovery</li>
                        <li>Memory Upgrade</li>
                        <li>Face ID Repairs</li>
                        <li>Running jumper with fly line wire</li>
                        <li>Flashing and Unlocking of Iphones</li>
                        <li>Understanding Zillion X works Wuxinji</li>
                        <li>Trouble shooting, fault finding and repair</li>
                        <li>Identification and understanding function of all ICs on the mother board</li>

                    </ul>

                </ul>

            </div>

            {/* <div className="masters col-md col-sm">
                <div className="courseHeadings">
                <h5 className="courseTitle">Masters</h5>
                    <h5>Cost: ₦250,000</h5>
                    <h5>Duration: 1 months</h5>
                    <h5>Phone-type: Iphone XR - 14 Pro-max </h5>
                    <h5 className="duration">Time: Monday - Friday (10:00 AM - 3:00 PM) daily</h5>
                </div>


                <div className="ulParent">
                <ul className="ulParent">
                <li>The following skills will be acquired during the masters class:</li>
                    <ul>
                        <li>HTML-2</li>
                        <li>CSS-3</li>
                        <li>Bootstrap-3</li>
                        <li>JavaScript-5</li>
                        <li>React</li>
                        <li>Java basics</li>
                        <li>Spring (Building of REST API end-points)</li>
                        <li>Spring Security with JWTS</li>
                        <li>Spring Data JPA</li>
                    </ul>

                </ul>

                </div>
            </div> */}
        </div>
    );
}

export default Courses;